@import '~www/themes/doctors/common/variables';

.b-choose-town-popup {
  font-family: $font-family-regular;
  min-height: 575px;

  &__title {
    font-size: 20px;
  }

  &__search-input {
    padding: 32px 40px !important;
  }

  &__towns-item-link {
    cursor: pointer;
  }

  &__major-towns {
    display: flex;
    flex-wrap: wrap;
  }

  &__major-towns-item {
    flex: 0 0 33.3333%;

    &_spb {
      flex: 1;
    }
  }

  &__major-towns-item-link {
    font-size: 20px;
    display: block;
    text-decoration: none;
    color: $color-neur-dark;
    font-weight: $weight-medium;
    margin-right: 10px;
    margin-bottom: 40px;
    transition: all 0.15s;

    &:hover {
      color: #107EC2;
    }
  }

  &__major-towns-item_is_active &__major-towns-item-link {
    color: #107EC2;
  }

  &__all-towns {
    padding: 0;
    margin: 0 -11px;
    display: flex;
    flex-wrap: wrap;
  }

  &__all-towns-item {
    margin: 0;
    font-size: $font-size-body-1;
    width: 33.333%;
    list-style: none;
  }

  &__all-towns-item-logo {
    height: 24px;
    object-fit: contain;
  }

  &__all-towns-item-link {
    text-decoration: none;
    color: #515459!important;
    font-size: $font-size-body-1;
    display: block;
    transition: all 0.15s;
    position: relative;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 11px;
    background: $ui-kit-bg-gray-0;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #107EC2;
      right: 9%;
      top: 50%;
      margin-top: -3px;
      display: none;
    }

    &:hover {
      background: #ECF1FB;
    }
  }

  &__all-towns-item_is_active > &__all-towns-item-link {
    color: #107EC2;
    font-weight: $weight-medium;
  }

  &__all-towns-item_is_active > &__all-towns-item-link::after {
    display: block;
  }
}
